// Get all notification
export const GET_NOTIFICATIONS_SAGA = "GET_NOTIFICATIONS_SAGA";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const ADD_ONE_NOTIFICATION = "ADD_ONE_NOTIFICATION";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";
export const GET_NOTIFICATIONS_START = "GET_NOTIFICATIONS_START";

// Get filtered notification
export const GET_FILTERED_NOTIFICATIONS_SAGA = "GET_FILTERED_NOTIFICATIONS_SAGA";
export const GET_FILTERED_NOTIFICATIONS_SUCCESS = "GET_FILTERED_NOTIFICATIONS_SUCCESS";
export const GET_FILTERED_NOTIFICATIONS_FAIL = "GET_FILTERED_NOTIFICATIONS_FAIL";
export const GET_FILTERED_NOTIFICATIONS_START = "GET_FILTERED_NOTIFICATIONS_START";

// Mark as Read
export const MARK_AS_READ_SAGA = "MARK_AS_READ_SAGA";
export const MARK_AS_READ_START = "MARK_AS_READ_START";
export const MARK_AS_READ_SUCCESS = "MARK_AS_READ_SUCCESS";
export const MARK_AS_READ_FAIL = "MARK_AS_READ_FAIL";