export { default as Success } from "assets/images/account-success.svg";
export { default as Logo } from "assets/images/logos/MOGUL_LOGO_WHITE_01.svg";
export { default as HeaderBackground } from "assets/images/headerbg.jpg";
export { default as Menu1 } from "assets/images/Menu1.svg";
export { default as Menu2 } from "assets/images/Menu2.svg";
export { default as Menu3 } from "assets/images/Menu3.svg";
export { default as Menu3Green } from "assets/images/Menu3Green.svg";
export { default as Menu3Black } from "assets/images/Menu3Black.svg";
export { default as Bell } from "assets/images/Bell.svg";
export { default as BellGreen } from "assets/images/BellGreen.svg";
export { default as Qrcode } from "assets/images/Qrcode.png";
export { default as Copy } from "assets/images/copy.svg";
export { default as View } from "assets/images/view.svg";
export { default as Delete } from "assets/images/delete.svg";
export { default as Card1 } from "assets/images/card1.png";
export { default as Card2 } from "assets/images/card2.png";
export { default as Card3 } from "assets/images/card3.png";
export { default as Home } from "assets/images/home.svg";
export { default as HomeLight } from "assets/images/propertieshp.svg";
export { default as HomeGreen } from "assets/images/homeGreen.svg";
export { default as HomeBlack } from "assets/images/homeBlack.svg";
export { default as LoginDark } from "assets/images/loginDark.svg";
export { default as LoginBlack } from "assets/images/loginBlack.svg";
export { default as RegisterDark } from "assets/images/registerDark.svg";
export { default as RegisterBlack } from "assets/images/registerBlack.svg";
export { default as HomeDark } from "assets/images/homeDark.svg";
export { default as InvestorBackground } from "assets/images/investbg.jpg";
export { default as Trending1 } from "assets/images/Trending1.jpg";
export { default as Up } from "assets/images/up.svg";
export { default as Star } from "assets/images/star.svg";
export { default as Upcoming1 } from "assets/images/Upcoming1.png";
export { default as CommingSoon } from "assets/images/CommingSoon.png";
export { default as Equals } from "assets/images/equal.svg";
export { default as More } from "assets/images/more.svg";
export { default as Checked } from "assets/images/Checked.svg";
export { default as Trash } from "assets/images/svgs/Trash.svg";
export { default as Phone } from "assets/images/svgs/phone.svg";
export { default as Location } from "assets/images/svgs/location.svg";
export { default as Leptop } from "assets/images/svgs/Leptop.svg";
export { default as AddButton } from "assets/images/addCreditCard.svg";
export { default as iIMG } from "assets/images/infoIcon.png";
export { default as errorImg } from "assets/images/error_Info_Icon.png";
export { default as check } from "assets/images/GreenCheck.svg";
export { default as ACH } from "assets/images/svgs/ACH.svg";
export { default as Wire } from "assets/images/svgs/Wire.svg";
export { default as CC } from "assets/images/svgs/CreditCard.svg";
export { default as Rental } from "assets/images/svgs/single-family-rental.svg";
export { default as Beds } from "assets/images/svgs/beds.svg";
export { default as Baths } from "assets/images/svgs/baths.svg";
export { default as Area } from "assets/images/svgs/dimensions.svg";
export { default as Occupency } from "assets/images/svgs/occupency.svg";
export { default as Occupied } from "assets/images/svgs/occupied.svg";
export { default as BuiltYear } from "assets/images/svgs/built-year.svg";
export { default as ActiveStepper } from "assets/images/svgs/active-stepper.svg";
export { default as Googlemaps } from "assets/images/svgs/Googlemaps.svg";
export { default as MarkerPin } from "assets/images/svgs/map-pin.svg";
export { default as Download } from "assets/images/svgs/download.svg";
export { default as Disclaimer } from "assets/images/svgs/outline.svg";
export { default as Increment } from "assets/images/svgs/increment.svg";
export { default as ArrowAngle } from "assets/images/arrow-angle.svg";
export { default as ArrowAngleDown } from "assets/images/arrow-angle-down.svg";
export { default as ReactDollar } from "assets/images/svgs/portfolio/react-dollar.svg";
export { default as Dollar } from "assets/images/svgs/portfolio/dollar.svg";
export { default as ArrowUpGreen } from "assets/images/svgs/portfolio/arrow-up-green.svg";
export { default as ArrowUpGreenSm } from "assets/images/svgs/portfolio/arrow-up-green-sm.svg";
export { default as ArrowUpBlack } from "assets/images/svgs/portfolio/arrow-up-black.svg";
export { default as ArrowDownBlack } from "assets/images/svgs/portfolio/arrow-down-black.svg";
export { default as ArrowDownRed } from "assets/images/svgs/portfolio/arrow-down-red.svg";
export { default as PropertyProfile } from "assets/images/svgs/portfolio/PropertyProfile.svg";
export { default as PropertyDisabled } from "assets/images/svgs/portfolio/PropertyDisabled.svg";
export { default as Vote } from "assets/images/svgs/portfolio/Vote.svg";
export { default as Appreciation } from "assets/images/svgs/portfolio/appreciation.svg";
export { default as Holdings } from "assets/images/svgs/portfolio/holdings.svg";
export { default as TotalRentalIncome } from "assets/images/svgs/portfolio/TotalRentalIncome.svg";
export { default as NextPayout } from "assets/images/svgs/portfolio/NextPayout.svg";
export { default as AssetsValue } from "assets/images/svgs/portfolio/assetsValue.svg";
export { default as PortfolioValue } from "assets/images/svgs/portfolio/PortfolioValue.svg";
export { default as PortfolioRentalIncon } from "assets/images/svgs/portfolio/portfolioRentalIncon.svg";
export { default as Lock } from "assets/images/svgs/lock.svg";
export { default as Logout } from "assets/images/svgs/log-out.svg";
export { default as Help } from "assets/images/svgs/Help.svg";
export { default as Settings } from "assets/images/svgs/settings.svg";
export { default as BellBlack } from "assets/images/svgs/bell-black.svg";
export { default as ProfileCircle } from "assets/images/svgs/profile-circle.svg";
export { default as Portfolio } from "assets/images/PropertyProfile.svg";
export { default as ArrowRight } from "assets/images/svgs/arrow-right.svg";
export { default as PaymentCard } from "assets/images/svgs/card.svg";
export { default as Process } from "assets/images/svgs/Process.svg";
export { default as Plaid } from "assets/images/plaid.png";
export { default as DoubleTick } from "assets/images/svgs/doubleTick.svg";
export { default as History } from "assets/images/history.svg";
export { default as Edit } from "assets/images/Edit.png";
export { default as PM1 } from "assets/images/PM1.png";
export { default as PM2 } from "assets/images/PM2.svg";
export { default as PM3 } from "assets/images/PM3.svg";
export { default as Initiate } from "assets/images/Initiate.svg";
export { default as Bank } from "assets/images/bank.svg";
export { default as Card } from "assets/images/card.svg";
export { default as Plus } from "assets/images/svgs/Plus.svg";
export { default as RedCheck } from "assets/images/svgs/RedCheck.svg";
export { default as GreenCheck } from "assets/images/svgs/GreenCheck.svg";
export { default as noNotification } from "assets/images/nocontent/noNotification.svg";
export { default as noTransactions } from "assets/images/nocontent/noTransactions.svg";
export { default as noInvestment } from "assets/images/nocontent/noInvestment.svg";
export { default as noGraph } from "assets/images/nocontent/noGraph.svg";
export { default as noDataAvailable } from "assets/images/nocontent/noDataAvailable.svg";
export { default as noDataAvailableDark } from "assets/images/nocontent/noDataAvailableDark.svg";
export { default as placeholderImg } from "assets/images/nocontent/placeholder-img.png";
export { default as noDonwloads } from "assets/images/nocontent/noDonwloads.svg";
export { default as CloseCircle } from "assets/images/svgs/close-circle.svg";
export { default as CloseCircleWhite } from "assets/images/svgs/close-circle-white.svg";
export { default as MarketingRating } from "assets/images/svgs/MarketingRating.svg";
export { default as PropertyGrowth } from "assets/images/svgs/PropertyGrowth.svg";
export { default as RentGrowth } from "assets/images/svgs/RentGrowth.svg";
export { default as Dotloader } from "assets/images/dotloader.gif";
export { default as GraphUp } from "assets/images/GraphUp.svg";
export { default as BankCard } from "assets/images/svgs/bankCard.svg";
export { default as UserAvtar } from "assets/images/svgs/userAvtar.svg";
export { default as UserCard } from "assets/images/svgs/userCard.svg";
export { default as BlueWallet } from "assets/images/svgs/wallet.svg";
export { default as NoBankCard } from "assets/images/svgs/noBankCard.svg";
export { default as NoProperty } from "assets/images/svgs/noProperty.svg";
export { default as NoProperties } from "assets/images/svgs/noProperties.svg";
export { default as BrowserOffering } from "assets/images/svgs/browserOffering.svg";
export { default as Referral } from "assets/images/svgs/Referral.svg";
export { default as UserTime } from "assets/images/svgs/user-time.svg";
export { default as Share } from "assets/images/svgs/share.svg";
export { default as WhatsApp } from "assets/images/Whatsapp.png";
export { default as Instagram } from "assets/images/instagram.png";
export { default as telegram } from "assets/images/telegram.png";
export { default as twitter } from "assets/images/twitter.png";
export { default as InviteFriend } from "assets/images/svgs/inviteFriend.svg";
export { default as ReceiveReward } from "assets/images/receiveReward.png";
export { default as ShareBlue } from "assets/images/svgs/shareBlue.svg";
export { default as RewardEarned } from "assets/images/svgs/rewardEarned.svg";
export { default as ReferralRegister } from "assets/images/svgs/referralRegister.svg";
export { default as AddFields } from "assets/images/addFields.svg";
export { default as RedCancle } from "assets/images/svgs/redCancle.svg";
export { default as Attachment } from "assets/images/svgs/attachment.svg";
export { default as Search } from "assets/images/svgs/search.svg";
export { default as VotedFor } from "assets/images/svgs/VotedFor.svg";
export { default as VotedForFilled } from "assets/images/svgs/VotedForFilled.svg";
export { default as VotedAgainst } from "assets/images/svgs/VotedAgainst.svg";
export { default as VotedAgainstFilled } from "assets/images/svgs/VotedAgainstFilled.svg";
export { default as Moonpay } from "assets/images/moon-pay.png";
export { default as Pay1 } from "assets/images/Pay1.svg";
export { default as Pay2 } from "assets/images/Pay2.svg";
export { default as Group } from "assets/images/svgs/Group.svg";
export { default as DollarIcon } from "assets/images/svgs/Dollar.svg";
export { default as NoDownload } from "assets/images/construction.svg";
export { default as MogulWeb } from "assets/images/logos/MOGUL_SYMBOL_LETTERING_WHITE-01.svg";
export { default as MogulMWeb } from "assets/images/logos/MOGUL_SYMBOL_LETTERING_WHITE-01.svg";
export { default as Learn } from "assets/images/svgs/Learn.svg";
export { default as ReferralVerify } from "assets/images/referralVerify.png";
export { default as MogulClubMenu } from "assets/images/MOGUL_LOGO_WHITE_01.png";
export { default as Fb } from "assets/images/fb.png";
export { default as AF1 } from "assets/images/AF1.svg";
export { default as AF2 } from "assets/images/AF2.svg";
export { default as AF3 } from "assets/images/AF3.png";
export { default as AF4 } from "assets/images/AF5.png";
export { default as S1 } from "assets/images/S1.png";
export { default as S2 } from "assets/images/S2.png";
export { default as S3 } from "assets/images/S3.png";
export { default as QR } from "assets/images/QR.png";
export { default as Help1 } from "assets/images/Help.png";
export { default as ID } from "assets/images/svgs/ID.png";
export { default as NBank } from "assets/images/svgs/Bank.svg";
export { default as CCard } from "assets/images/svgs/Card.png";
export { default as OpenBookTeam } from "assets/images/home-page/openbook.png";
export { default as FoundersSignature } from "assets/images/home-page/signature.png";
export { default as FoundersShakeWithLogo } from "assets/images/home-page/alex-joey-composition 2.png";
export { default as ContractIcon } from "assets/images/home-page/icons/contract.svg";
export { default as AttachMoneyIcon } from "assets/images/home-page/icons/attach_money.svg";
export { default as RealEstateAgentIcon } from "assets/images/home-page/icons/real_estate_agent.svg";
export { default as StreamIcon } from "assets/images/home-page/icons/stream.svg";
export { default as WorkspacesIcon } from "assets/images/home-page/icons/workspaces.svg";

// Home page media ticker images
export { default as Media1 } from "assets/images/media/benzinga.svg";
export { default as Media2 } from "assets/images/media/bloomberg.svg";
export { default as Media3 } from "assets/images/media/hackernoon.svg";
export { default as Media4 } from "assets/images/media/markets-insider.svg";
export { default as Media5 } from "assets/images/media/market-watch.svg";
export { default as Media6 } from "assets/images/media/morning-star.svg";
export { default as Media7 } from "assets/images/media/seeking-alpha.svg";
export { default as Media8 } from "assets/images/media/yahoo-finance.svg";

// Home page twitter section animations
export { default as TeslaAnimation } from "assets/images/home-page/animations/01Tesla.mp4";
export { default as PelotonAnimation } from "assets/images/home-page/animations/02Peloton.mp4";
export { default as CoronaAnimation } from "assets/images/home-page/animations/03Corona.mp4";

// Properties mocked Investors
export { default as BowserInvestor1 } from "assets/images/investors-properties/bowser/investor-1.jpg";
export { default as BowserInvestor2 } from "assets/images/investors-properties/bowser/investor-2.jpg";
export { default as BowserInvestor3 } from "assets/images/investors-properties/bowser/investor-3.jpg";
export { default as JomarInvestor1 } from "assets/images/investors-properties/jomar/investor-1.jpg";
export { default as JomarInvestor2 } from "assets/images/investors-properties/jomar/investor-2.jpg";
export { default as JomarInvestor3 } from "assets/images/investors-properties/jomar/investor-3.jpg";
export { default as TallheathInvestor1 } from "assets/images/investors-properties/tallheath/investor-1.jpg";
export { default as TallheathInvestor2 } from "assets/images/investors-properties/tallheath/investor-2.jpg";
export { default as TallheathInvestor3 } from "assets/images/investors-properties/tallheath/investor-3.jpg";

// Home page banned section property cards
export { default as HomePropertyCard1 } from "assets/images/home-page/property-card-0.png";
export { default as HomePropertyCard2 } from "assets/images/home-page/property-card-1.png";
export { default as HomePropertyCard3 } from "assets/images/home-page/property-card-2.png";
