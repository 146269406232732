import { forwardRef } from "react";

import PropTypes from "prop-types";

// Custom styles for MKBox
import MKBoxRoot from "./MKBoxRoot";

const MKBox = forwardRef(
  (
    { variant, /*bgColor, color,*/ opacity, borderRadius, shadow, /*coloredShadow,*/ paddingTop, ...rest },
    ref
  ) => (
    <MKBoxRoot
      {...rest}
      ref={ref}
      ownerState={{
        variant,
        // bgColor,
        // color,
        opacity,
        borderRadius,
        shadow,
        // coloredShadow,
        paddingTop,
      }}
    />
  )
);

// Setting default values for the props of MKBox
MKBox.defaultProps = {
  variant: "contained",
  // bgColor: "transparent",
  // color: "dark",
  opacity: 1,
  borderRadius: "none",
  shadow: "none",
  // coloredShadow: "none",
  paddingTop: "",
};

// Typechecking props for the MKBox
MKBox.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  // bgColor: PropTypes.string,
  // color: PropTypes.string,
  opacity: PropTypes.number,
  borderRadius: PropTypes.string,
  shadow: PropTypes.string,
  // coloredShadow: PropTypes.oneOf([
  //   "primary",
  //   "secondary",
  //   "info",
  //   "success",
  //   "warning",
  //   "error",
  //   "light",
  //   "dark",
  //   "none",
  // ]),
  paddingTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default MKBox;
