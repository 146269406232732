export const routePaths = {
  LANDING_PAGE_PATH: "/",
  LOGIN_PATH: "/login",
  ABOUT_US_PATH: "/about",
  OUR_BLOGS_PATH: "/blog",
  BLOG_DETAILS_PATH: "/blog-details/:id/:title",
  HOW_IT_WORKS_PATH: "/how-it-works",
  LOGIN_REGISTER_PATH: "/register",
  LOGIN_REGISTER_ID_PATH: "/register/:id",
  PERSONAL_DETAILS_PATH: "/register/personal-information",
  LOGIN_FORGOT_PATH: "/forgot-password",
  LOGIN_RESET_PATH: "/reset-password/:id",
  EMAIL_VERIFIED_PATH: "/email-verification/:id",
  PAGE_NOT_FOUND_PATH: "/page-not-found",
  INVESTOR_PATH: "/marketplace",
  PROPERTY_PROFILE_PATH: "/property-profile",
  PROPERTY_DETAILS_PATH: "/property-details-card",
  PROPERTY_DETAILS_ID_PATH: "/property-details-card/:id",
  PROPERTY_MGR_PROPERTY_PROPOSALS_PATH: "/property-manager/proposals",
  PROPERTY_MGR_PROPERTY_PROPOSALS_ID_PATH: "/property-manager/proposals/:id",
  PROPERTY_MGR_VIEW_PROPOSAL_PATH: "/property-manager/view-proposal",
  PROPERTY_MGR_CREATE_PROPOSAL_PATH: "/property-manager/create-proposal",
  PROPERTY_MGR_INITIATE_TRANSACTION_PATH:
    "/property-manager/initiate-transaction",
  PROPERTY_MGR_RENT_PAYOUT_PATH: "/property-manager/rent-payout/:id",
  DEMO_MODAL_PATH: "/demo-modal",
  MOONPAY_DEMO_PATH: "/moonpay-demo",
  REWARDS_PATH: "/IR",
  PROFILE_PATH: "/profile",
  ACCOUNTS_PATH: "/accounts",
  ACCOUNT_SETTINGS_PATH: "/account-settings",
  PORTFOLIO_PATH: "/portfolio",
  WALLET_PATH: "/wallet",
  NOTIFICATIONS_PATH: "/notifications",
  TRANSACTION_HISTORY_PATH: "/transaction-history",
  REFERRALS_TRANSACTIONS_PATH: "/referrals-transactions",
  REFERRALS_TRANSACTIONS_ID_PATH: "/referrals-transactions/:id",
  FORCE_UPDATE_PASSWORD_PATH: "/force-update-password",
  FORCE_UPDATE_PASSWORD_ID_PATH: "/force-update-password/:id",
  WHY_REAL_ESTATE_PATH: "/why-real-estate",
  MOGUL_CLUB_PATH: "/investech-club",
};
