export const GET_PROPOSAL_LIST_SAGA = "GET_PROPOSAL_LIST_SAGA";
export const GET_PROPOSAL_LIST_START = "GET_PROPOSAL_LIST_START";
export const GET_PROPOSAL_LIST_SUCCESS = "GET_PROPOSAL_LIST_SUCCESS";
export const GET_PROPOSAL_LIST_FAIL = "GET_PROPOSAL_LIST_FAIL";

export const GET_PROPOSAL_DETAILS_SAGA = "GET_PROPOSAL_DETAILS_SAGA";
export const GET_PROPOSAL_DETAILS_START = "GET_PROPOSAL_DETAILS_START";
export const GET_PROPOSAL_DETAILS_SUCCESS = "GET_PROPOSAL_DETAILS_SUCCESS";
export const GET_PROPOSAL_DETAILS_FAIL = "GET_PROPOSAL_DETAILS_FAIL";

export const GET_PROPOSAL_OVERVIEW_SAGA = "GET_PROPOSAL_OVERVIEW_SAGA";
export const GET_PROPOSAL_OVERVIEW_START = "GET_PROPOSAL_OVERVIEW_START";
export const GET_PROPOSAL_OVERVIEW_SUCCESS = "GET_PROPOSAL_OVERVIEW_SUCCESS";
export const GET_PROPOSAL_OVERVIEW_FAIL = "GET_PROPOSAL_OVERVIEW_FAIL";

export const CREATE_PROPOSAL_SAGA = "CREATE_PROPOSAL_SAGA";
export const CREATE_PROPOSAL_START = "CREATE_PROPOSAL_START";
export const CREATE_PROPOSAL_SUCCESS = "CREATE_PROPOSAL_SUCCESS";
export const CREATE_PROPOSAL_FAIL = "CREATE_PROPOSAL_FAIL";

export const UPLOAD_DOCUMENT_SAGA = "UPLOAD_DOCUMENT_SAGA";
export const UPLOAD_DOCUMENT_START = "UPLOAD_DOCUMENT_START";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_FAIL = "UPLOAD_DOCUMENT_FAIL";

export const CREATE_TRANSACTION_SAGA = "CREATE_TRANSACTION_SAGA";
export const CREATE_TRANSACTION_START = "CREATE_TRANSACTION_START";
export const CREATE_TRANSACTION_SUCCESS = "CREATE_TRANSACTION_SUCCESS";
export const CREATE_TRANSACTION_FAIL = "CREATE_TRANSACTION_FAIL";

export const CREATE_VOTE_SAGA = "CREATE_VOTE_SAGA";
export const CREATE_VOTE_START = "CREATE_VOTE_START";
export const CREATE_VOTE_SUCCESS = "CREATE_VOTE_SUCCESS";
export const CREATE_VOTE_FAIL = "CREATE_VOTE_FAIL";

export const GET_COOWNERS_LIST_SAGA = "GET_COOWNERS_LIST_SAGA";
export const GET_COOWNERS_LIST_START = "GET_COOWNERS_LIST_START";
export const GET_COOWNERS_LIST_SUCCESS = "GET_COOWNERS_LIST_SUCCESS";
export const GET_COOWNERS_LIST_FAIL = "GET_COOWNERS_LIST_FAIL";
