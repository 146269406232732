const {
    REACT_APP_END_POINT_URL,
    REACT_APP_CMS_END_POINT_URL,
    REACT_APP_INSTAGRAM_LINK,
    REACT_APP_TWITTER_LINK,
    REACT_APP_PRIVACY_LINK,
    REACT_APP_HELP_LINK,
    REACT_APP_LINKEDIN_LINK,
    REACT_APP_YOUTUBE_LINK,
    REACT_APP_ZENDESK_LINK,
    REACT_APP_REGISTER_FORM_ID,
    REACT_APP_SELL_MY_PROPERTY_ID,
    REACT_APP_GOOGLE_TAG_MANAGER_ID,
    REACT_APP_FAQ_LINK,
    REACT_APP_LANDING_PAGE_BLOG_LINK1,
    REACT_APP_LANDING_PAGE_BLOG_LINK2,
    REACT_APP_TERMS_AND_CONDITION_LINK,
    REACT_APP_PLATFORM_LISTING_FEE,
    REACT_APP_INVESTOR_LOGIN,
    REACT_APP_INVESTOR_REGISTER,
} = process.env

const useConfig = () => {
    const CMS_END_POINT_URL = REACT_APP_CMS_END_POINT_URL
    const INSTAGRAM_LINK = REACT_APP_INSTAGRAM_LINK
    const TWITTER_LINK = REACT_APP_TWITTER_LINK
    const LINKEDIN_LINK = REACT_APP_LINKEDIN_LINK
    const YOUTUBE_LINK = REACT_APP_YOUTUBE_LINK
    const ZENDESK_LINK = REACT_APP_ZENDESK_LINK
    const PRIVACY_LINK = REACT_APP_PRIVACY_LINK
    const HELP_LINK = REACT_APP_HELP_LINK
    const REGISTER_FORM_ID = REACT_APP_REGISTER_FORM_ID
    const SELL_MY_PROPERTY_ID = REACT_APP_SELL_MY_PROPERTY_ID
    const GOOGLE_TAG_MANAGER_ID = REACT_APP_GOOGLE_TAG_MANAGER_ID
    const FAQ_LINK = REACT_APP_FAQ_LINK
    const BLOG_ID_1 = REACT_APP_LANDING_PAGE_BLOG_LINK1
    const BLOG_ID_2 = REACT_APP_LANDING_PAGE_BLOG_LINK2
    const END_POINT_URL = REACT_APP_END_POINT_URL
    const TERMS_AND_CONDITION = REACT_APP_TERMS_AND_CONDITION_LINK
    const PLATFORM_LISTING_FEE = REACT_APP_PLATFORM_LISTING_FEE
    const LOGIN_LINK = REACT_APP_INVESTOR_LOGIN
    const REGISTER_LINK = REACT_APP_INVESTOR_REGISTER

    return {
        BLOG_ID_1,
        BLOG_ID_2,
        CMS_END_POINT_URL,
        INSTAGRAM_LINK,
        TWITTER_LINK,
        LINKEDIN_LINK,
        YOUTUBE_LINK,
        ZENDESK_LINK,
        PRIVACY_LINK,
        FAQ_LINK,
        HELP_LINK,
        REGISTER_FORM_ID,
        SELL_MY_PROPERTY_ID,
        GOOGLE_TAG_MANAGER_ID,
        END_POINT_URL,
        TERMS_AND_CONDITION,
        PLATFORM_LISTING_FEE,
        LOGIN_LINK,
        REGISTER_LINK,
    }
}

export default useConfig
