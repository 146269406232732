// Set personal details

export const SET_EMAIL_ENTERED = "SET_EMAIL_ENTERED";

export const SET_PERSONAL_DETAILS_SAGA = "SET_PERSONAL_DETAILS_SAGA";
export const SET_PERSONAL_DETAILS_START = "SET_PERSONAL_DETAILS_START";
export const SET_PERSONAL_DETAILS_SUCCESS = "SET_PERSONAL_DETAILS_SUCCESS";
export const SET_PERSONAL_DETAILS_FAIL = "SET_PERSONAL_DETAILS_FAIL";

export const GET_WALLET_ADDRESS_SAGA = "GET_WALLET_ADDRESS_SAGA";
export const GET_WALLET_ADDRESS_START = "GET_WALLET_ADDRESS_START";
export const GET_WALLET_ADDRESS_SUCCESS = "GET_WALLET_ADDRESS_SUCCESS";
export const GET_WALLET_ADDRESS_FAIL = "GET_WALLET_ADDRESS_FAIL";

export const GET_MOONPAY_URL_SAGA = "GET_MOONPAY_URL_SAGA";
export const GET_MOONPAY_URL_START = "GET_MOONPAY_URL_START";
export const GET_MOONPAY_URL_SUCCESS = "GET_MOONPAY_URL_SUCCESS";
export const GET_MOONPAY_URL_FAIL = "GET_MOONPAY_URL_FAIL";

export const GET_PAYMENT_METHODS_SAGA = "GET_PAYMENT_METHODS_SAGA";
export const GET_PAYMENT_METHODS_START = "GET_PAYMENT_METHODS_START";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
export const GET_PAYMENT_METHODS_FAIL = "GET_PAYMENT_METHODS_FAIL";

export const GET_REFREE_DETAILS_SAGA = "GET_REFREE_DETAILS_SAGA";
export const GET_REFREE_DETAILS_START = "GET_REFREE_DETAILS_START";
export const GET_REFREE_DETAILS_SUCCESS = "GET_REFREE_DETAILS_SUCCESS";
export const GET_REFREE_DETAILS_FAIL = "GET_REFREE_DETAILS_FAIL";

export const GET_REWARD_TRANSACTIONS_SAGA = "GET_REWARD_TRANSACTIONS_SAGA";
export const GET_REWARD_TRANSACTIONS_START = "GET_REWARD_TRANSACTIONS_START";
export const GET_REWARD_TRANSACTIONS_SUCCESS = "GET_REWARD_TRANSACTIONS_SUCCESS";
export const GET_REWARD_TRANSACTIONS_FAIL = "GET_REWARD_TRANSACTIONS_FAIL";

export const GET_REWARD_SINGLE_TRANSACTION_SAGA = "GET_REWARD_SINGLE_TRANSACTION_SAGA";
export const GET_REWARD_SINGLE_TRANSACTION_START = "GET_REWARD_SINGLE_TRANSACTION_START";
export const GET_REWARD_SINGLE_TRANSACTION_SUCCESS = "GET_REWARD_SINGLE_TRANSACTION_SUCCESS";
export const GET_REWARD_SINGLE_TRANSACTION_FAIL = "GET_REWARD_SINGLE_TRANSACTION_FAIL";