export const GET_PROPERTYDETAILS_SAGA = "GET_PROPERTYDETAILS_SAGA";
export const GET_PROPERTYDETAILS_START = "GET_PROPERTYDETAILS_START";
export const GET_PROPERTYDETAILS_SUCCESS = "GET_PROPERTYDETAILS_SUCCESS";
export const GET_PROPERTYDETAILS_FAIL = "GET_PROPERTYDETAILS_FAIL";

export const GET_PROPERTYLIST_SAGA = "GET_PROPERTYLIST_SAGA";
export const GET_PROPERTYLIST_START = "GET_PROPERTYLIST_START";
export const GET_PROPERTYLIST_SUCCESS = "GET_PROPERTYLIST_SUCCESS";
export const GET_PROPERTYLIST_FAIL = "GET_PROPERTYLIST_FAIL";

// similar Properties
export const GET_SIMILAR_PROPERTYLIST_SAGA = "GET_SIMILAR_PROPERTYLIST_SAGA";
export const GET_SIMILAR_PROPERTYLIST_START = "GET_SIMILAR_PROPERTYLIST_START";
export const GET_SIMILAR_PROPERTYLIST_SUCCESS = "GET_SIMILAR_PROPERTYLIST_SUCCESS";
export const GET_SIMILAR_PROPERTYLIST_FAIL = "GET_SIMILAR_PROPERTYLIST_FAIL";

// Invest Property
export const INVEST_PROPERTY_SAGA = "INVEST_PROPERTY_SAGA";
export const INVEST_PROPERTY_START = "INVEST_PROPERTY_START";
export const INVEST_PROPERTY_SUCCESS = "INVEST_PROPERTY_SUCCESS";
export const INVEST_PROPERTY_FAIL = "INVEST_PROPERTY_FAIL";

export const GET_RESERVE_TRANSACTIONS_SAGA = "GET_RESERVE_TRANSACTIONS_SAGA";
export const GET_RESERVE_TRANSACTIONS_START = "GET_RESERVE_TRANSACTIONS_START";
export const GET_RESERVE_TRANSACTIONS_SUCCESS = "GET_RESERVE_TRANSACTIONS_SUCCESS";
export const GET_RESERVE_TRANSACTIONS_FAIL = "GET_RESERVE_TRANSACTIONS_FAIL";
