export * from "./auth/auth";
export * from "./user/user";
export * from "./modal/modal";
export * from "./accounts";
export * from "./marketplace";
export * from "./notifications";
export * from "./tour/tour";
export * from "./pManager";
export * from "./common";

export const resetApp = (payload) => ({
  type: "RESET_APP",
  payload,
});
