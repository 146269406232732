// Material Kit 2 React base styles
import colors from "assets/theme/base/colors";

export default {
  styleOverrides: {
    root: {
        "& .MuiTabs-indicator": {
            backgroundColor: colors.secondary.dark,
        },
    },
  },
};

