// Get Plaid Link Token

export const GET_PLAID_TOKEN_SAGA = "GET_PLAID_TOKEN_SAGA";
export const GET_PLAID_TOKEN_START = "GET_PLAID_TOKEN_START";
export const GET_PLAID_TOKEN_SUCCESS = "GET_PLAID_TOKEN_SUCCESS";
export const GET_PLAID_TOKEN_FAIL = "GET_PLAID_TOKEN_FAIL";

// Add ACH Bank Account
export const ADD_ACH_BANK_ACCOUNT_SAGA = "ADD_ACH_BANK_ACCOUNT_SAGA";
export const ADD_ACH_BANK_ACCOUNT_START = "ADD_ACH_BANK_ACCOUNT_START";
export const ADD_ACH_BANK_ACCOUNT_SUCCESS = "ADD_ACH_BANK_ACCOUNT_SUCCESS";
export const ADD_ACH_BANK_ACCOUNT_FAIL = "ADD_ACH_BANK_ACCOUNT_FAIL";

// Add Wire Bank Account
export const ADD_WIRE_BANK_ACCOUNT_SAGA = "ADD_WIRE_BANK_ACCOUNT_SAGA";
export const ADD_WIRE_BANK_ACCOUNT_START = "ADD_WIRE_BANK_ACCOUNT_START";
export const ADD_WIRE_BANK_ACCOUNT_SUCCESS = "ADD_WIRE_BANK_ACCOUNT_SUCCESS";
export const ADD_WIRE_BANK_ACCOUNT_FAIL = "ADD_WIRE_BANK_ACCOUNT_FAIL";

// Get All lists of bank accounts

export const GET_LIST_OF_BANK_ACCOUNT_SAGA = "GET_LIST_OF_BANK_ACCOUNT_SAGA";
export const GET_LIST_OF_BANK_ACCOUNT_START = "GET_LIST_OF_BANK_ACCOUNT_START";
export const GET_LIST_OF_BANK_ACCOUNT_SUCCESS =
  "GET_LIST_OF_BANK_ACCOUNT_SUCCESS";
export const GET_LIST_OF_BANK_ACCOUNT_FAIL = "GET_LIST_OF_BANK_ACCOUNT_FAIL";

// Add Card
export const ADD_CARD_SAGA = "ADD_CARD_SAGA";
export const ADD_CARD_START = "ADD_CARD_START";
export const ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS";
export const ADD_CARD_FAIL = "ADD_CARD_FAIL";

// Get All lists of cards

export const GET_LIST_OF_CARDS_SAGA = "GET_LIST_OF_CARDS_SAGA";
export const GET_LIST_OF_CARDS_START = "GET_LIST_OF_CARDS_START";
export const GET_LIST_OF_CARDS_SUCCESS = "GET_LIST_OF_CARDS_SUCCESS";
export const GET_LIST_OF_CARDS_FAIL = "GET_LIST_OF_CARDS_FAIL";

// Deposit currency using bank account

export const DEPOSIT_CURRENCY_SAGA = "DEPOSIT_CURRENCY_SAGA";
export const DEPOSIT_CURRENCY_START = "DEPOSIT_CURRENCY_START";
export const DEPOSIT_CURRENCY_SUCCESS = "DEPOSIT_CURRENCY_SUCCESS";
export const DEPOSIT_CURRENCY_FAIL = "DEPOSIT_CURRENCY_FAIL";

// Withdraw currency using bank account

export const WITHDRAW_CURRENCY_SAGA = "WITHDRAW_CURRENCY_SAGA";
export const WITHDRAW_CURRENCY_START = "WITHDRAW_CURRENCY_START";
export const WITHDRAW_CURRENCY_SUCCESS = "WITHDRAW_CURRENCY_SUCCESS";
export const WITHDRAW_CURRENCY_FAIL = "WITHDRAW_CURRENCY_FAIL";

// Get Wallet Balance

export const GET_WALLET_BALANCE_SAGA = "GET_WALLET_BALANCE_SAGA";
export const GET_WALLET_BALANCE_START = "GET_WALLET_BALANCE_START";
export const GET_WALLET_BALANCE_SUCCESS = "GET_WALLET_BALANCE_SUCCESS";
export const GET_WALLET_BALANCE_FAIL = "GET_WALLET_BALANCE_FAIL";

// Delete Card

export const DELETE_CARD_SAGA = "DELETE_CARD_SAGA";
export const DELETE_CARD_START = "DELETE_CARD_START";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAIL = "DELETE_CARD_FAIL";

// Get Bank Details
export const GET_BANK_DETAILS_SAGA = "GET_BANK_DETAILS_SAGA";
export const GET_BANK_DETAILS_START = "GET_BANK_DETAILS_START";
export const GET_BANK_DETAILS_SUCCESS = "GET_BANK_DETAILS_SUCCESS";
export const GET_BANK_DETAILS_FAIL = "GET_BANK_DETAILS_FAIL";

// Get Transactions list
export const GET_LIST_OF_TRANSACTIONS_SAGA = "GET_LIST_OF_TRANSACTIONS_SAGA";
export const GET_LIST_OF_TRANSACTIONS_START = "GET_LIST_OF_TRANSACTIONS_START";
export const GET_LIST_OF_TRANSACTIONS_SUCCESS =
  "GET_LIST_OF_TRANSACTIONS_SUCCESS";
export const GET_LIST_OF_TRANSACTIONS_FAIL = "GET_LIST_OF_TRANSACTIONS_FAIL";

// Get Limits & Service fess

export const GET_SERVICE_FEES_SAGA = "GET_SERVICE_FEES_SAGA";
export const GET_SERVICE_FEES_START = "GET_SERVICE_FEES_START";
export const GET_SERVICE_FEES_SUCCESS = "GET_SERVICE_FEES_SUCCESS";
export const GET_SERVICE_FEES_FAIL = "GET_SERVICE_FEES_FAIL";

// Portfolio Overview
export const GET_PF_OVERVIEW_SAGA = "GET_PF_OVERVIEW_SAGA";
export const GET_PF_OVERVIEW_SUCCESS = "GET_PF_OVERVIEW_SUCCESS";
export const GET_PF_OVERVIEW_FAIL = "GET_PF_OVERVIEW_FAIL";

// Portfolio Listing
export const GET_PF_LIST_SAGA = "GET_PF_LIST_SAGA";
export const GET_PF_LIST_SUCCESS = "GET_PF_LIST_SUCCESS";
export const GET_PF_LIST_FAIL = "GET_PF_LIST_FAIL";

// Specific Portfolio asset cards Data
export const GET_PF_ASSET_SAGA = "GET_PF_ASSET_SAGA";
export const GET_PF_ASSET_SUCCESS = "GET_PF_ASSET_SUCCESS";
export const GET_PF_ASSET_FAIL = "GET_PF_ASSET_FAIL";

// Property Transactions
export const GET_PROPERTY_TXN_SAGA = "GET_PROPERTY_TXN_SAGA";
export const GET_PROPERTY_TXN_SUCCESS = "GET_PROPERTY_TXN_SUCCESS";
export const GET_PROPERTY_TXN_FAIL = "GET_PROPERTY_TXN_FAIL";

// Get Wire Instruction
export const GET_WIRE_INSTRUCTION = "GET_WIRE_INSTRUCTION";
export const GET_WIRE_INSTRUCTION_START = "GET_WIRE_INSTRUCTION_START";
export const GET_WIRE_INSTRUCTION_SUCCESS = "GET_WIRE_INSTRUCTION_SUCCESS";
export const GET_WIRE_INSTRUCTION_FAIL = "GET_WIRE_INSTRUCTION_FAIL";

// Update token sold
export const UPDATE_TOKENS_SOLD = "UPDATE_TOKENS_SOLD";

// File upoad
export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPLOAD_START = "FILE_UPLOAD_START";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAIL = "FILE_UPLOAD_FAIL";

// Deposit rent
export const DEPOSIT_RENT = "DEPOSIT_RENT";
export const DEPOSIT_RENT_START = "DEPOSIT_RENT_START";
export const DEPOSIT_RENT_SUCCESS = "DEPOSIT_RENT_SUCCESS";
export const DEPOSIT_RENT_FAIL = "DEPOSIT_RENT_FAIL";

// GET rent transactions
export const GET_RENT_TXN = "GET_RENT_TXN";
export const GET_RENT_TXN_START = "GET_RENT_TXN_START";
export const GET_RENT_TXN_SUCCESS = "GET_RENT_TXN_SUCCESS";
export const GET_RENT_TXN_FAIL = "GET_RENT_TXN_FAIL";

