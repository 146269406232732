// Material Kit 2 React Base Styles
// import colors from "assets/theme/base/colors";

// const { transparentColor } = colors;

export default {
  styleOverrides: {
    root: {
      // "&:hover": {
      //   backgroundColor: transparentColor.main,
      // },
    },
  },
};
