import styled from "styled-components";

export const WrapHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  z-index: 99;

  /* @media (max-width: 540px) {
    padding-inline: 20px;
  } */
`;
